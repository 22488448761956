.ant-form-item {
    padding: 0px !important;
    margin: 0px !important;
    margin-bottom: 10px !important;
}

.small-text-grey {
    color: grey;
    font-size: 90%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.p-50 {
    padding: 50px;
}

.pl-20 {
    padding-left: 20px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-50 {
    margin-top: 50px;
}

.pt-10 {
    padding-top: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pt-20 {
    padding-top: 20px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-5 {
    padding-top: 5px;
}

.pt-100 {
    padding-top: 100px;
}

.card-product-title {
    font-weight: 500;
    font-size: 110%;
    padding-right: 5px;
}

.card-product-price {
    color: #8f8f8f;
}

.card-promo-caption {
    color: #8f8f8f;
    font-size: 90%;
}

.card-effective-date-caption {
    color: #8f8f8f;
    font-size: 80%;
}

.detail-product-title {
    font-weight: 500;
    font-size: 140%;
}

.detail-product-price {
    font-weight: 500;
    font-size: 131%;
}

.detail-110 {
    font-size: 100%;
}

.detail-product-caption {
    color: #8f8f8f;
}

.detail-product-description {
    color: #5d5d5d;
}

.header-home-top {
    color: #4b4b4b;
    font-size: x-large;
    margin-top: 18px;
    margin-left: -3px;
}

.layout {
    min-height: 97vh;
    background-color: #ffffff;
}

.text-small-grey-nopadding {
    font-size: 90%;
    color: #8e8e8e;
}

.card-product {
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 3%;
    margin-right: 3%;
    box-shadow: 0px 2px 7px 2px rgb(236 236 236);
    text-align: left;
    padding: 0px;
    padding-bottom: 15px;
    min-height: 190px;
    cursor: pointer;
}

.product-img-center {
    display: flex;
    justify-content: center;
    align-items: center
}

.img-product-card {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.p-chosen-name {
    font-size: 90%;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-left: 10px;
    min-height: 25px;
}

.card-box-shadow {
    border-radius: 5px;
    margin-top: 20px;
    box-shadow: 0px 1px 5px 1px rgb(225, 225, 225);
    text-align: left;
    padding: 20px;
    min-height: 100px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 5px !important;
    font-size: 85%;
}

.ant-collapse-content-box {
    padding: 5px !important;
}

.icon-title-info {
    color: #64b48f;
    font-size: large;
}

.text-title-info {
    color: #727272;
    padding-left: 7px;
    font-size: medium;
    font-weight: 600;
}

.desktop-header-search {
    height: 50px;
    padding: 0 20px !important;
    line-height: 50px;
    background-color: #f0f2f5;
    color: #64b48f;
}

.text-small-grey-nopadding {
    font-size: 90%;
    color: #8e8e8e;
}

.chosen-product {
    width: 94%;
    margin-top: 20px;
    margin-left: 3%;
}

.chosen-product-text {
    font-weight: 700;
    font-size: 110%;
    text-transform: capitalize;
}

.chosen-product-other {
    color: #f33232;
    font-weight: 500;
    font-size: 110%;
    cursor: pointer;
    float: right;
}

.card-filter {
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 3%;
    margin-right: 3%;
    box-shadow: 0px 2px 7px 2px rgb(236 236 236);
    text-align: left;
    padding: 10px;
    min-height: 420px;
    cursor: pointer;
}

.text-small-grey {
    font-size: 90%;
    color: #8e8e8e;
    padding-bottom: 7px;
}

.same-ant-input {
    position: relative;
    display: inline-flex;
    width: 96%;
    min-width: 0;
    padding: 4px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}

.ant-spin {
    color: #f33232;
}

.ant-spin-dot-item {
    background-color: #f33232 !important;
}

.spin-loading {
    padding-top: 150px;
    padding-bottom: 150px;
    text-align: center;
}

.fixed-top-button-scroll {
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    position: fixed;
    top: 90px;
    z-index: 200;
    right: 30px;
    font-size: 80%;
    width: 160px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.fixed-bottom-button {
    background-color: white;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 200;
    padding: 7px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.text-small-green-nopadding {
    font-size: 85%;
    color: #f33232;
}

.text-extra-small-grey-nopadding {
    font-size: 80%;
    color: #8e8e8e;
}

.text-small-black-nopadding {
    font-size: 85%;
    color: #000;
    text-transform: capitalize;
}

.sigCanvas{
    background-color: #f8f8f8;
}
