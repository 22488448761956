.ant-carousel .slick-dots li button {
    background: #64b48f;
    opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: #64b48f;
}

.var-thumb-logo {
    height: 50px;
    width: 50px;
}

.card-preview-thumb-product {
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 0px 2px 7px 2px rgb(236 236 236);
    text-align: left;
    padding: 3px;
    cursor: pointer;
}

.card-preview-thumb-product-active {
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 0px 2px 7px 2px rgb(136 205 149);
    text-align: left;
    padding: 3px;
    cursor: pointer;
}

.row-thumb-prev {
    padding-bottom: 20px;
}

.colorname-choosen {
    text-align: center;
    font-size: medium;
    color: #8e8e8e;
    text-transform: capitalize;
}


.outer {
    margin: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.inner {
    display: inline-block;
    white-space: normal;
    width: 20em;
    padding: 10px;
    margin: 10px;
    margin-bottom: 20px;
}
