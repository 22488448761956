.footer-reason-buy{
    background-color: #fff;
    padding-left: 10px;
    padding-bottom: 20px;
}

.footer-r-title{
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 115%;
    text-align: center;
}

.fr-img{
    margin-left: 20px;
}

.fr-text{
    padding-left: 0px;
    margin-left: -10px;
    padding-top: 7px;
}

.frr{
    margin-top: 12px;
}
