.img-1 {
    min-height: 62vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /*filter: opacity(2) brightness(0.9);*/
    z-index: 10;
}

.img-2 {
    min-height: 40vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    z-index: 10;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.section p {
    position: relative;
    top: 12px;
    font-size: 18px;
    width: 500px;
}

.section-1 {
    position: relative;
    text-align: right;
    z-index: 20 !important;
    top: 0vh;
    color: #000;
    font-size: 100%;
}

.section-1 h2 {
    font-weight: 500;
    border-radius: 3px;
    margin: 10px;
    background-color: rgba(243, 243, 243, 0.87);
}
