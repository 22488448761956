.slick-arrow.slick-prev {
    font-size: 10px;

}

.ant-carousel .slick-prev::before {
    content: '<';
    display: block;
    position: relative;
    bottom: 10px;
    right: 10px;
    font-size: 25px;
    color: gray;
    background-color: white;
    border: 2px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;

}

.slick-arrow.slick-next {
    font-size: 10px;
}

.ant-carousel .slick-next::before {
    content: '>';
    display: block;
    position: relative;
    right: 3px;
    bottom: 8px;
    width: 100px;
    font-size: 25px;
    color: gray;
    background-color: white;
    border: 2px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}


.card-testimony{
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 2%;
    margin-right: 2%;
    box-shadow: 0px 2px 7px 2px rgb(236 236 236);
    text-align: left;
    padding: 3px;
    min-height: 100px;
}

.testi-img{
    margin-left: 15px;
    margin-top: 10px;
}

.testi-name{
    padding-top: 15px;
    font-size: 95%;
    font-weight: 600;
}

.testi-info{
    color: #8e8e8e;
}

.testi-p{
    margin-top: 2px;
    text-align: justify;
    color: #6b6b6b;
    font-size: 90%;
    padding-left: 15px;
    padding-right: 15px;
}
